'use client';

import { faAngleRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TitleDivider } from '@grimme/components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Container, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { useLocale } from 'next-intl';
import { useTranslations } from '@/lib/i18n/use-translations';
import { getRegisterUrl } from '~/utils/auth/register';
import { butterAPI } from '@/lib/apis/butter-api';
import { LoginButton } from '../../login-button';
import { Timeline } from '../timeline/timeline';
import { Root, classes } from './styles';

interface IBannerProps {
  cta_button_label?: string;
  cta_button_url?: string;
  elementIdToScrollTo?: string;
  features?: {
    title: string;
  }[];
  headline?: string;
  image?: string;
  subheadline?: string;
  video_hq?: string;
  video_lq?: string;
}

const HEADER_HEIGHT_IN_PX = 54;
const BANNER_HEIGHT = '100vh';

export const Banner = (props: IBannerProps) => {
  const {
    cta_button_label,
    cta_button_url,
    elementIdToScrollTo,
    features,
    headline,
    image,
    subheadline,
    video_hq,
    video_lq,
  } = props;

  const t = useTranslations();
  const locale = useLocale();
  const insights = useAppInsightsContext();

  const backgroundClasses = classNames(
    classes.backgroundVideo,
    classes.backgroundOverlay,
  );

  const fallbackRegisterUrl = getRegisterUrl(locale);
  const registerUrl = cta_button_url || fallbackRegisterUrl;
  const scrollText = t('myGRIMME_banner_scroll_down', 'Scroll down');
  const videoPosterImage = butterAPI.prepareFullHDImage(image);

  const scrollDown = () => {
    if (!elementIdToScrollTo) return;

    const targetScrollElement =
      global.document.getElementById(elementIdToScrollTo);

    if (!targetScrollElement) return;

    global.window.scrollTo({
      top: targetScrollElement.offsetTop - HEADER_HEIGHT_IN_PX,
    });
  };

  return (
    <Root className={classes.root}>
      <video
        autoPlay
        loop
        muted
        playsInline
        className={classNames(classes.backgroundVideo, 'hidden sm:block')}
        style={{ height: BANNER_HEIGHT }}
        poster={videoPosterImage}
      >
        <source src={video_hq} type="video/mp4" />
      </video>
      <video
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        className={classNames(classes.backgroundVideo, 'sm:hidden')}
        style={{ height: BANNER_HEIGHT }}
        poster={videoPosterImage}
      >
        <source src={video_lq} type="video/mp4" />
      </video>
      <div
        className={backgroundClasses}
        style={{ height: BANNER_HEIGHT }}
      ></div>
      <Container
        maxWidth={false}
        className={classes.banner}
        style={{
          height: BANNER_HEIGHT,
        }}
        disableGutters
      >
        <Container className={classes.fullHeight}>
          <Grid
            className={classes.fullHeight}
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item xs={12} />
            <Grid container item xs={12} alignItems="center">
              <Grid item xs={12}>
                <Grid item sm={6}>
                  <Typography variant="h1">{headline}</Typography>
                  <TitleDivider />
                  <Typography
                    className={classes.bannerSubtitleRegular}
                    component="h2"
                    variant="h4"
                  >
                    {subheadline}
                  </Typography>
                  <Grid className={classes.buttons}>
                    <LoginButton
                      classes={classes.login}
                      endIcon={
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className={classes.arrow}
                        />
                      }
                      modifier="dark"
                      variant="secondary"
                    />
                    <a
                      href={registerUrl}
                      title={cta_button_label}
                      className={classes.registerButtonWrapper}
                    >
                      <Button
                        endIcon={<FontAwesomeIcon icon={faAngleRight} />}
                        modifier="light"
                        onClick={() => {
                          insights.trackEvent({ name: 'Started Registration' });
                        }}
                        variant="primary"
                      >
                        {cta_button_label}
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} alignContent="flex-end">
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                className={classes.arrowIndicatorRegular}
              >
                <div
                  aria-label={scrollText}
                  className={classes.buttonScroll}
                  onClick={scrollDown}
                  role="button"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Timeline features={features} />
    </Root>
  );
};
