import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-2RKUOKOV.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-46KSEZ5P.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-JBFL7O37.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-JSFTEYOL.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-LNJFS74T.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-LNR35GNP.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-NVT5VD6O.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-QRJ4HRLD.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-T4UPECES.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/dist/lib/chunk-Z4FU2Y7F.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default","AccordionItem","AccordionTrigger","AccordionContent"] */ "/app/node_modules/@grimme/buttery/node_modules/@grimme/ui-components/dist/lib/accordion.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/node_modules/@grimme/ui-components/dist/lib/carousel.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/node_modules/@grimme/ui-components/dist/lib/separator.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default","TabsList","TabsTrigger","TabsContent"] */ "/app/node_modules/@grimme/buttery/node_modules/@grimme/ui-components/dist/lib/tabs.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@grimme/buttery/node_modules/@grimme/ui-components/dist/lib/teaser.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Banner"] */ "/app/src/components/banner/banner/banner.tsx");
